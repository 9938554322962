import createActionTypes from '../../actions/createActionTypes'

const GET_FAMILY_DETAIL = createActionTypes('GET_FAMILY_DETAIL')
const getFamilyDetail = id => dispatch =>
  dispatch({
    types: GET_FAMILY_DETAIL,
    api: {
      //   url: 'http://api-test.tinyvoice.com.cn',
      path: '/family/info',
      data: {
        id: Number(id),
      },
    },
  })
const initialState = {
  id: 0,
  name: '',
  icon: '',
}

const _ = (state = initialState, action) => {
  switch (action.type) {
    case GET_FAMILY_DETAIL.SUCCESS: {
      return {
        ...state,
        ...action.payload.data,
      }
    }

    default:
      return state
  }
}

export {_ as default, getFamilyDetail}
