import createActionTypes from '../../actions/createActionTypes'

const GET_RECHARGE_ACTIVITY_INFO = createActionTypes(
  'GET_RECHARGE_ACTIVITY_INFO'
)
const getRechargeActivityInfo = params => dispatch =>
  dispatch({
    types: GET_RECHARGE_ACTIVITY_INFO,
    api: {
      path: '/activity/user_recharge_info',
      data: params,
    },
  })
const initialState = {
  rechargeActivityInfo: {
    enable: false,
    beginTime: 0,
    endTime: 0,
    beginTimeStr: '',
    endTimeStr: '',
    name: '',
    showBeginTime: 0,
    showEndTime: 0,
    firstVip: {
      day: 0,
      effects: [],
      vipId: 0,
    },
    dayVip: {
      day: 0,
      effects: [],
      vipId: 0,
      threshold: 0,
    },
    monthVip: {
      threshold: 0,
      day: 0,
      vipId: 0,
      effects: [],
    },
    weekVip: {
      threshold: 0,
      day: 0,
      vipId: 0,
      effects: [],
    },
    monthTask: {
      total: 0,
      finish: 0,
    },
    weekTask: {
      total: 0,
      finish: 0,
    },
    dayTask: {
      total: 0,
      finish: 0,
    },
    firstAward: false,
  },
}

const _ = (state = initialState, action) => {
  switch (action.type) {
    case GET_RECHARGE_ACTIVITY_INFO.SUCCESS: {
      return {
        ...state,
        rechargeActivityInfo: action.payload.data,
      }
    }

    default:
      return state
  }
}

export {_ as default, getRechargeActivityInfo}
