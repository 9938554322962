import Loadable from 'react-loadable'

const hazaRoutes = [
  {
    path: '/hybrid/game/guessgift/rule',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridGameGuessgiftRule" */ '../pages/guessgiftRule'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/game/guessgift/rule/en',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridGameGuessgiftRuleEn" */ '../pages/guessgiftRule/guessgiftRuleEn'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/game/guessgift/rule/ar',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridGameGuessgiftRuleAr" */ '../pages/guessgiftRule/guessgiftRuleAr'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/game/guessgift/rule/tr',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridGameGuessgiftRuleTr" */ '../pages/guessgiftRule/guessgiftRuleTr'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/help/questions',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridHelpQuestions" */ '../pages/helpQuestions'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/help/questions/en',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridHelpQuestionsEn" */ '../pages/helpQuestions/helpQuestionsEn'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/help/questions/ar',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridHelpQuestionsAr" */ '../pages/helpQuestions/helpQuestionAr'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/help/questions/tr',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridHelpQuestionsTr" */ '../pages/helpQuestions/helpQuestionsTr'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/x/about/dp',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridAboutDpX" */ '../pages/aboutDp_x'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/x/about/ts',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridAboutTsX" */ '../pages/aboutTs_x'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/x/about/pp',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridAboutPpX" */ '../pages/aboutPp_x'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/about/contact',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridAboutContact" */ '../pages/aboutContact'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/about/contact/en',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridAboutContactEn" */ '../pages/aboutContact/aboutContactEn'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/about/contact/ar',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridAboutContactAr" */ '../pages/aboutContact/aboutContactAr'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/about/contact/tr',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridAboutContactTr" */ '../pages/aboutContact/aboutContactTr'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/medal/rule',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridMedalRule" */ '../pages/medalRule'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/billboard/rule',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridBillboardRule" */ '../pages/billboardRule'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/billboard/rule/en',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridBillboardRuleEn" */ '../pages/billboardRule/billboardRuleEn'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/billboard/rule/zh',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridBillboardRuleCn" */ '../pages/billboardRule/billboardRuleCn'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/billboard/rule/ar',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridBillboardRuleAr" */ '../pages/billboardRule/billboardRuleAr'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/billboard/rule/tr',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridBillboardRuleTr" */ '../pages/billboardRule/billboardRuleTr'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/game/slot/rule',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridSlotRule" */ '../pages/slotRule'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/game/slot/rule/en',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridSlotRuleEn" */ '../pages/slotRule/slotRuleEn'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/game/slot/rule/zh',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridSlotRuleCn" */ '../pages/slotRule/slotRuleCn'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/game/slot/rule/ar',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridSlotRuleAr" */ '../pages/slotRule/slotRuleAr'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/game/slot/rule/tr',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridSlotRuleTr" */ '../pages/slotRule/slotRuleTr'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/family/rule',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridFamilyRule" */ '../pages/familyRule'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/family/rule/en',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridFamilyRuleEn" */ '../pages/familyRule/familyRuleEn'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/family/rule/zh',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridFamilyRuleCn" */ '../pages/familyRule/familyRuleCn'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/family/rule/ar',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridFamilyRuleAr" */ '../pages/familyRule/familyRuleAr'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/family/rule/tr',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridFamilyRuleTr" */ '../pages/familyRule/familyRuleTr'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/vip/description',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridVipDescription" */ '../pages/VipDesciption'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/vip/description/zh',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridVipDescriptionZh" */ '../pages/VipDesciption/VipDesciptionZh'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/vip/description/en',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridVipDescriptionEn" */ '../pages/VipDesciption/VipDesciptionEn'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/vip/description/ar',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridVipDescriptionAr" */ '../pages/VipDesciption/VipDescriptionAr'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/vip/description/tr',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridVipDescriptionTr" */ '../pages/VipDesciption/VipDescriptionTr'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/share/family',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridShareFamily" */ '../pages/share/Family'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/payment',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridPayment" */ '../pages/recharge/payment'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/game/guessgift/fruits/rule/en',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridFruitsRuleEn" */ '../pages/guessgiftRule/fruitsRuleEn'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/game/guessgift/fruits/rule/tr',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridFruitsRuleTr" */ '../pages/guessgiftRule/fruitsRuleTr'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/game/guessgift/fruits/rule/ar',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridFruitsRuleAr" */ '../pages/guessgiftRule/fruitsRuleAr'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/rechargeActivity',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridRechargeActivity" */ '../pages/rechargeActivity'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/weekGiftList',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridWeekGiftList" */ '../pages/weekGiftList'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/weekGiftList/en',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridWeekGiftListEn" */ '../pages/weekGiftList/weekGiftListEn'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/weekGiftList/tr',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridWeekGiftListTr" */ '../pages/weekGiftList/weekGiftListTr'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/weekGiftList/ar',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridWeekGiftListAr" */ '../pages/weekGiftList/weekGiftListAr'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/apply',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridApply" */ '../pages/applyActivity'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/loading',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-loading" */ '../pages/loading'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/coinsSeller',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hyBridCoinsSeller" */ '../pages/coinsSeller'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/activity/readPome',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hyBridActivityReadPome" */ '../pages/activity/readPome'),
      loading: () => null,
    }),
  },
]

export default hazaRoutes
