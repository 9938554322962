import createActionTypes from '../actions/createActionTypes'

const GET_USER_INFO = createActionTypes('GET_USER_INFO')
const getUserInfo = () => ({
  api: {
    // 查自己
    path: '/user/profile',
    data: {},
  },
  types: GET_USER_INFO,
})

const getTargetUserInfo = mid => ({
  api: {
    // 查别人
    path: '/user/info',
    data: {
      ...(mid && {mid: Number(mid)}),
    },
  },
  types: GET_USER_INFO,
})

const getUserMailInfo = () => ({
  api: {
    path: '/user/mail_info',
    data: {},
  },
  types: GET_USER_INFO,
})

const initialState = {
  mid: 0,
  nick: '',
  avatar: '',
  gender: 1,
  phone: '',
  hasRealName: false,
  mailName: '',
  mailPhone: '',
  mailAddr: '',
}

const _ = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_INFO.SUCCESS: {
      const member = action.payload.data
      return {
        ...state,
        ...member,
      }
    }
    default:
      return state
  }
}

export {_ as default, getUserInfo, getTargetUserInfo, getUserMailInfo}
