import createActionTypes from '../../actions/createActionTypes'

const GET_COUNTRY_LIST = createActionTypes('GET_COUNTRY_LIST')
const getCountryList = params => dispatch =>
  dispatch({
    types: GET_COUNTRY_LIST,
    api: {
      //   url: 'http://api-test.tinyvoice.com.cn',
      path: '/cfg/country_pm',
      data: params,
    },
  })
const GET_PAYMENT_METHODS = createActionTypes('GET_PAYMENT_METHODS')
const getPaymentMethods = params => dispatch =>
  dispatch({
    types: GET_PAYMENT_METHODS,
    api: {
      path: '/order/list_pm_product',
      data: params,
    },
  })
const CLEAR_PAYMENT_METHODS = createActionTypes('CLEAR_PAYMENT_METHODS')
const clearPaymentMethods = () => dispatch =>
  dispatch({
    types: GET_PAYMENT_METHODS,
  })
const initialState = {
  countryList: [],
  paymentMethods: [],
  orderData: {},
  userInfo: {
    mid: 0,
    nick: '',
    avatar: '',
    gender: 1,
    phone: '',
    hasRealName: false,
    mailName: '',
    mailPhone: '',
    mailAddr: '',
  },
}
const CREATE_PAY_ORDER = createActionTypes('CREATE_PAY_ORDER')
const createPayOrder = params => dispatch =>
  dispatch({
    types: CREATE_PAY_ORDER,
    api: {
      path: '/order/create',
      data: params,
    },
  })
const GET_PAY_USER_INFO = createActionTypes('GET_PAY_USER_INFO')
const getPayUserInfo = mid => ({
  api: {
    // 查别人
    path: '/user/info',
    data: {
      ...(mid && {mid: Number(mid)}),
    },
  },
  types: GET_PAY_USER_INFO,
})

const _ = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTRY_LIST.SUCCESS: {
      return {
        ...state,
        countryList: action.payload.data,
      }
    }
    case GET_PAYMENT_METHODS.SUCCESS: {
      return {
        ...state,
        paymentMethods: action.payload.data,
      }
    }
    case GET_PAYMENT_METHODS.FAILURE: {
      return {
        ...state,
        paymentMethods: [],
      }
    }
    case CREATE_PAY_ORDER.SUCCESS: {
      return {
        ...state,
        orderData: action.payload.data,
      }
    }
    case GET_PAY_USER_INFO.SUCCESS: {
      const member = action.payload.data
      return {
        ...state,
        userInfo: member,
      }
    }
    case GET_PAY_USER_INFO.FAILURE: {
      return {
        ...state,
        userInfo: {
          mid: 0,
          nick: '',
          avatar: '',
          gender: 1,
          phone: '',
          hasRealName: false,
          mailName: '',
          mailPhone: '',
          mailAddr: '',
        },
      }
    }
    case CLEAR_PAYMENT_METHODS.REQUEST: {
      return {
        ...state,
        paymentMethods: [],
      }
    }

    default:
      return state
  }
}

export {
  _ as default,
  getCountryList,
  getPaymentMethods,
  createPayOrder,
  getPayUserInfo,
  clearPaymentMethods,
}
