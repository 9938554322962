import {combineReducers} from 'redux'
import skill from './skill'
import room from './room'
import profile from './profile'
import post from './post'
import reply from './reply'
import invite from './invite'
import family from './family'

export default combineReducers({
  skill,
  room,
  profile,
  post,
  reply,
  invite,
  family,
})
