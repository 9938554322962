import {API_ROOT} from '../config'

function padStart(originStr, targetLen, padStr) {
  let targetLength = Number(targetLen)
  const originString = String(originStr)
  const padString = String(typeof padStr !== 'undefined' ? padStr : ' ')

  if (originString.length > targetLength) {
    return String(originString)
  }

  targetLength -= originString.length

  const padding = new Array(
    Math.ceil(targetLength / padString.length) + 1
  ).join(padString)

  return padding.slice(0, targetLength) + originString
}

function formateDuration(dur) {
  const duration = Math.round(dur) || 0
  const minute = padStart(Math.floor(duration / 60), 2, '0')
  const second = padStart(duration % 60, 2, '0')
  return `${minute}:${second}`
}

function getImgUrl(img, size) {
  if (img.signurl) {
    return img.signurl
  }
  return `https://file.xxx.com/img/view/id/${img.id}${
    size ? `/sz/${size}` : ''
  }`
}

function getBase64(file, callback) {
  return new Promise((res, rej) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      res(reader.result)
      if (callback) {
        callback(reader.result)
      }
    })
    reader.readAsDataURL(file)
  })
}

function request({url, method = 'POST', params = {}, timeout = 0}) {
  const p1 = new Promise(async resolve => {
    const response = await fetch(url, {
      method,
      body: JSON.stringify(params),
    })
    const body = await response.json()
    resolve(body)
  })
  p1.catch(() => {})

  const promises = [p1]
  if (timeout) {
    const p2 = new Promise((resolve, reject) => {
      setTimeout(() => reject(new Error('timeout')), timeout)
    })
    p2.catch(() => {})
    promises.push(p2)
  }

  return Promise.race(promises)
}

function isNode() {
  return typeof window === 'undefined'
}

// not used
function getHostname() {
  return isNode()
    ? API_ROOT.replace('http://', '').replace('https://', '')
    : window.location.hostname
}

function isPhone(phone) {
  return /^[0-9]{1,14}$/.test(phone)
}

function isDevelopment() {
  return ENVIRONMENT === 'development' || ENVIRONMENT === 'development2'
}

function isMobilePhone(phone) {
  return /^1[3|4|5|7|8][0-9]{9}$/.test(phone)
}

function getAvatarUrl(aId, size = 228) {
  // 新版默认头像
  const defaultAvatarId = 632031656
  let avatarId = aId
  // 1 - male, 2 - female
  if (!avatarId || avatarId === 1 || avatarId === 2) {
    avatarId = defaultAvatarId
  }
  return `//file.xxx.com/account/avatar/id/${avatarId}/sz/${size}`
}

function noLessThanVersion(nowVersion, targetVersion) {
  const nowArr = nowVersion.split('.').map(item => Number(item))
  const targetArr = targetVersion.split('.').map(item => Number(item))
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < nowArr.length; i++) {
    if (nowArr[i] !== targetArr[i]) {
      return nowArr[i] > targetArr[i]
    }
  }
  return true
}

export {
  padStart,
  formateDuration,
  getImgUrl,
  request,
  isNode,
  getHostname,
  isPhone,
  isMobilePhone,
  getAvatarUrl,
  noLessThanVersion,
  getBase64,
  isDevelopment,
}
