import Loadable from 'react-loadable'

const kissoRoutes = [
  {
    path: '/home_social',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-homeSocial" */ '../pages/home_social'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/social/help/questions',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridSocialHelpQuestions" */ '../pages/helpQuestions_social'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/social/help/questions/en',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridSocialHelpQuestionsEn" */ '../pages/helpQuestions_social/helpQuestionsEn'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/social/help/questions/ar',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridSocialHelpQuestionsAr" */ '../pages/helpQuestions_social/helpQuestionAr'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/social/help/questions/tr',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridSocialHelpQuestionsTr" */ '../pages/helpQuestions_social/helpQuestionsTr'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/social/about/ts',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridAboutTsSocial" */ '../pages/aboutTs_social'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/social/about/pp',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridAboutPpSocial" */ '../pages/aboutPp_social'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/social/about/dp',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridAboutDpSocial" */ '../pages/aboutDp_social'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/share/dynamic',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridShareDynamic" */ '../pages/share/dynamic'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/vip/autoRenew',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hyBridVipAutoRenew" */ '../pages/vipAutoRenew'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/vip/autoRenew/zh',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hyBridVipAutoRenewZh" */ '../pages/vipAutoRenew/vipAutoRenewZh'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/vip/autoRenew/en',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hyBridVipAutoRenewEn" */ '../pages/vipAutoRenew/vipAutoRenewEn'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/vip/autoRenew/th',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hyBridVipAutoRenewTh" */ '../pages/vipAutoRenew/vipAutoRenewTh'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/vip/autoRenew/my',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hyBridVipAutoRenewMy" */ '../pages/vipAutoRenew/vipAutoRenewMy'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/vip/autoRenew/id',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hyBridVipAutoRenewId" */ '../pages/vipAutoRenew/vipAutoRenewId'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/vip/autoRenew/vi',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hyBridVipAutoRenewVi" */ '../pages/vipAutoRenew/vipAutoRenewVi'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/vip/autoRenew/tr',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hyBridVipAutoRenewTr" */ '../pages/vipAutoRenew/vipAutoRenewTr'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/vip/autoRenew/zh_TW',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hyBridVipAutoRenewTw" */ '../pages/vipAutoRenew/vipAutoRenewTw'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/vip/autoRenew/ar',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hyBridVipAutoRenewAr" */ '../pages/vipAutoRenew/vipAutoRenewAr'),
      loading: () => null,
    }),
  },
]

export default kissoRoutes
