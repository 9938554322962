import axios from 'axios'
import {decamelizeKeys, camelizeKeys} from 'humps'
// 开发环境下，webpack 会自动替换成 mock.dev
import initMock from '../../../mock/mock.pro'
import isNode from '../../utils/isNode'
import {login} from '../../utils/pwHybrid'
import {isLH, isPW, isWX, isX, isSocial} from '../../utils/ua'
import {API_ROOT, API_PREFIX} from '../../config'
import {getNextUrl} from '../../utils/url'
import {isDevelopment} from '../../utils'

initMock(axios)

const fixApiPath = path => (isNode ? path : `${API_PREFIX}${path}`)

// eslint-disable-next-line no-undef
const apiHost = isNode ? API_ROOT : window.location.origin
// const apiHost = isNode ? API_ROOT : 'http://api-test.tinyvoice.com.cn'

const getFinalUrl = (root, path) => {
  if (root) {
    // 支持 cors 的服务，不需要走 proxy
    return `${root}${path}`
  }
  // if (path === '/user/info') {
  //   return `http://api-test.tinyvoice.com.cn${path}`
  // }
  return `${apiHost}${fixApiPath(path)}`
}

const api = ctx => store => next => action => {
  if (!action.api) {
    return next(action)
  }

  const {
    api: {root, path, method = 'post', params = {}, data = {}, headers = {}},
    types,
    ...rest
  } = action
  next({
    ...rest,
    type: types.REQUEST,
  })

  const state = store.getState()
  const {userAgent, hostname} = state.env
  const {deviceinfo} = state

  let {h_app: hAPP} = deviceinfo
  if (isX(hostname)) {
    hAPP = 0
  } else if (isSocial(hostname)) {
    hAPP = 1
  }

  const finalData = decamelizeKeys({
    ...deviceinfo,
    // h_dt: isPW(userAgent) ? 2 : 3,
    // eslint-disable-next-line no-nested-ternary
    h_h5plat: isPW(userAgent) ? 1 : isWX(userAgent) ? 2 : 1,
    // h_dt: isAndroid(userAgent) ? 1 : 0, // 临时加的，只是兼容旧版本app没有添加h_dt字段，等新版本app上线后，删除
    h_app: hAPP,
    ...data,
  })
  const midToken = {
    h_mid:
      Number(ctx && ctx.cookies && ctx.cookies.get && ctx.cookies.get('mid')) ||
      0,
    h_token:
      ctx &&
      ctx.cookies &&
      ctx.cookies.get &&
      decodeURIComponent(ctx.cookies.get('token')),
  }
  if (!midToken.h_mid || !midToken.h_token) {
    const {h_mid: mid = 0, h_token: token = ''} = finalData
    midToken.h_mid = mid
    midToken.h_token = token
  }

  const requestConfig = {
    method,
    headers: {
      // 添加 x-forwarded-for 字段后，api 那边能识别出触发该 node 端请求对应的客户端 ip 。
      ...[
        'user-agent',
        'referer',
        'x-forwarded-for',
        'x-real-ip',
        'cookie',
      ].reduce((acc, headerKey) => {
        const headerValue =
          ctx &&
          ctx.request &&
          ctx.request.headers &&
          ctx.request.headers[headerKey]
        // headers 里设置空值会报错，这里需要判断
        if (headerValue) {
          acc[headerKey] = headerValue
        }
        return acc
      }, {}),
      ...headers,
      ...{'h5-magic-niu': true},
      ...(isDevelopment() && {'h5-magic-dev': true}),
    },
    params: {
      ...params,
    },
    url: getFinalUrl(root, path),
    data: {
      ...finalData,
      // 服务端直接发送请求的时候，需要带上 cookie 里的 token 和 mid
      ...(isNode &&
        !isLH(userAgent, (ctx && ctx.request && ctx.request.hostname) || '') &&
        midToken),
    },
  }

  return axios(requestConfig)
    .then(response => {
      const {data: resData} = response
      if (resData.ret !== 1) {
        const err = new Error()
        err.response = response
        throw err
      }
      const formatedData = camelizeKeys(resData)
      next({
        ...rest,
        type: types.SUCCESS,
        payload: formatedData,
      })
      return {payload: formatedData}
    })
    .catch(err => {
      if (!err.request && !err.response) {
        // 非网络和接口的错误（比如 reducer 中的错误）
        throw err
      }
      let msg = ''
      if (!err.response) {
        msg = '网络异常'
      } else if (err.response.status >= 200 && err.response.status < 300) {
        msg =
          (err.response.data &&
            (err.response.data.data
              ? err.response.data.data.detail || err.response.data.data.msg
              : err.response.data.detail || err.response.data.msg)) ||
          ''
      } else if (err.response.status === 401) {
        msg = '登录失效'
        if (isNode) {
          ctx.status = 302
          if (isLH(ctx.userAgent, ctx.request.hostname)) {
            ctx.redirect(
              `/hybrid/lh/login?next=${encodeURIComponent(ctx.request.href)}`
            )
          } else {
            ctx.redirect(
              `/login?next=${getNextUrl(
                ctx.request.path,
                `?${ctx.request.querystring}`
              )}`
            )
          }
        } else {
          // window.location.pathname = '/login'
          // window.history.replaceState({}, 'login', '/login')
          // eslint-disable-next-line no-lonely-if
          if (
            isLH(
              (navigator && navigator.userAgent) || '',
              window.location.hostname
            )
          ) {
            window.H.replace(
              `/hybrid/lh/login?next=${encodeURIComponent(
                window.location.href
              )}`
            )
          } else {
            login(null, ({mid, token}) => {
              // eslint-disable-next-line no-console
              console.log(mid, token)
              window.location.reload()
            })
            // window.H.replace(`/login?next=${window.location.pathname}`)
            /*
            window.H.replace(
              `/login?next=${encodeURIComponent(
                `${window.location.pathname}${window.location.search}`
              )}`
            )
            */
          }
        }
      } else if (err.response.status === 404) {
        msg = 'Not Found'
        if (isNode) {
          ctx.status = 404
          if (isLH(ctx.userAgent, ctx.request.hostname)) {
            ctx.redirect(`/hybrid/lh/404`)
          } else {
            ctx.redirect(`/404`)
          }
        } else {
          // window.location.pathname = '/login'
          // window.history.replaceState({}, 'login', '/login')
          // eslint-disable-next-line no-lonely-if
          if (
            isLH(
              (navigator && navigator.userAgent) || '',
              window.location.hostname
            )
          ) {
            window.H.replace(`/hybrid/lh/404`)
          } else {
            window.H.replace(`/404`)
          }
        }
      } else {
        msg = err || '服务器内部错误'
      }
      // eslint-disable-next-line no-console
      console.error('api.js error')
      if (err.response) {
        // eslint-disable-next-line no-console
        console.error(`response data: ${JSON.stringify(err.response.data)}`)
      }
      // eslint-disable-next-line no-console
      console.error(`msg: ${msg}`)
      // eslint-disable-next-line no-console
      console.error(`url: ${getFinalUrl(root, path)}`)
      // eslint-disable-next-line no-console
      console.error(`request data: ${JSON.stringify(finalData)}`)
      next({
        ...rest,
        ...(err.response &&
          err.response.data && {payload: camelizeKeys(err.response.data)}),
        type: types.FAILURE,
      })
      throw msg
    })
}

export default api
