import Loadable from 'react-loadable'

export default [
  {
    path: '/404',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-NotFound" */ '../pages/404'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/about/dp',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridAboutDp" */ '../pages/aboutDp'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/about/ts',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridAboutTs" */ '../pages/aboutTs'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/about/pp',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridAboutPp" */ '../pages/aboutPp'),
      loading: () => null,
    }),
  },
  {
    path: '/mobile/upload',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-WithdrawRule" */ '../pages/mobile/uploadAudio'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/billboard/test_entry',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-BillboardTestEntry" */ '../pages/billboard/testEntry'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/billboard',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-Billboard" */ '../pages/billboard'),
      loading: () => null,
    }),
  },
]
