export const API_ROOT = {
  development: 'http://api-test.tinyvoice.com.cn',
  development2: 'http://api-test2.tinyvoice.com.cn',
  test: 'http://127.0.0.1:8612',
  test2: 'http://127.0.0.1:8612',
  production: 'http://api-in.hazaclub.com',
  production2: 'http://api-in.kissoclub.com',
}[ENVIRONMENT]

export const API_PREFIX = '/api'
