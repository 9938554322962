import Loadable from 'react-loadable'
import globalRoutes from './global'
import lhRoutes from './lh'
import hazaRoutes from './haza'
import kissoRoutes from './kisso'

export const routes = [
  {
    path: '/hybrid/recorder',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-HybridRecorder" */ '../pages/billboard/Rcorder'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/billboard/avatarUpload',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-BillboardAvatarUpload" */ '../pages/billboard/avatarUpload'),
      loading: () => null,
    }),
  },
  // my
  {
    path: '/withdraw/rule_yun',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-WithdrawRuleYun" */ '../pages/withdraw/rule_yun'),
      loading: () => null,
    }),
  },
  {
    path: '/',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-Home" */ '../pages/home_niu'),
      loading: () => null,
    }),
  },
  {
    path: '/home_simple',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-HomeSimple" */ '../pages/home'),
      loading: () => null,
    }),
  },
  {
    path: '/home_sd',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-HomeSd" */ '../pages/home_sd'),
      loading: () => null,
    }),
  },
  {
    path: '/home_x',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-HomeX" */ '../pages/home_x'),
      loading: () => null,
    }),
  },
  {
    path: '/a',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-ShortA" */ '../pages/home'),
      loading: () => null,
    }),
  },
  {
    path: '/open',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-Open" */ '../pages/openapp'),
      loading: () => null,
    }),
  },
  {
    path: '/download',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-Download" */ '../pages/download'),
      loading: () => null,
    }),
  },
  {
    path: '/chat/dialog',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-ChatDialog" */ '../pages/chat/dialog'),
      loading: () => null,
    }),
  },
  {
    path: '/chat/entry',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-ChatEntry" */ '../pages/chat/entry'),
      loading: () => null,
    }),
  },
  {
    path: '/chat/jump',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-ChatJump" */ '../pages/chat/jump'),
      loading: () => null,
    }),
  },
  {
    path: '/login',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-Login" */ '../pages/login'),
      loading: () => null,
    }),
  },
  {
    path: '/share/profile',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-ShareProfile" */ '../pages/share/profile'),
      loading: () => null,
    }),
  },
  {
    path: '/share/post',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-SharPost" */ '../pages/share/post'),
      loading: () => null,
    }),
  },
  {
    path: '/statement',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-Statement" */ '../pages/statement'),
      loading: () => null,
    }),
  },
  {
    path: '/share/reply/sub',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-SharReplySub" */ '../pages/share/reply/SubReply'),
      loading: () => null,
    }),
  },
  {
    path: '/share/skill_x',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-ShareSkillX" */ '../pages/share/skill_x'),
      loading: () => null,
    }),
  },
  {
    path: '/share/skill',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-ShareSkill" */ '../pages/share/skill'),
      loading: () => null,
    }),
  },
  {
    path: '/share/invite_swiper',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-ShareInviteSwiper" */ '../pages/share/invite_swiper'),
      loading: () => null,
    }),
  },
  {
    path: '/wallet',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-Wallet" */ '../pages/wallet'),
      loading: () => null,
    }),
  },
  {
    path: '/withdraw',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-Withdraw" */ '../pages/withdraw/entry'),
      loading: () => null,
    }),
  },
  {
    path: '/withdraw/bindBank',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-WithdrawBindBank" */ '../pages/withdraw/bindBank'),
      loading: () => null,
    }),
  },
  {
    path: '/withdraw/bindAlipay',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-WithdrawBindAlipay" */ '../pages/withdraw/bindAlipay'),
      loading: () => null,
    }),
  },
  {
    path: '/withdraw/main',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-WithdrawMain" */ '../pages/withdraw/main'),
      loading: () => null,
    }),
  },
  {
    path: '/charge',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-Recharge" */ '../pages/recharge/main'),
      loading: () => null,
    }),
  },
  {
    path: '/charge/alipay',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-RechargeAlipay" */ '../pages/recharge/alipay'),
      loading: () => null,
    }),
  },
  {
    path: '/charge/wxh5pay',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-RechargeWxh5pay" */ '../pages/recharge/wxh5pay'),
      loading: () => null,
    }),
  },
  {
    path: '/hybrid/share/family',
    exact: true,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-hybridShareFamily" */ '../pages/share/Family'),
      loading: () => null,
    }),
  },
]

const allRoutes = routes
  .concat(globalRoutes, lhRoutes, hazaRoutes, kissoRoutes)
  .concat({
    path: '',
    exact: false,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "page-NotFound" */ '../pages/404'),
      loading: () => null,
    }),
  })

export default store => allRoutes
